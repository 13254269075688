export default {
	data: () => ({
		milagePrograms: {
			id: 0,
			name: "",
			description: "",
			logo_url: "",
		},
		loading: false,
		error: "",
		backUrl: "/milage-programs"
	}),
	computed: {
		selectAllList() {
			if (this.departmentList.length > 0) {
				return (
					_.where(this.departmentList, { isSelected: true }).length ===
					this.departmentList.length
				);
			}
			return false;
		}
	},
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.milagePrograms = {
				id: 0,
				name: "",
				description: "",
				logo_url: ""

			};
		},
		validateMilagePrograms() {
			this.milagePrograms.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		add() {
			let _vm = this;
			let fd = new FormData();
			if (_vm.milagePrograms.logoImage) {
				fd.append("logoImage", _vm.milagePrograms.logoImage);
			}
			Object.keys(_vm.milagePrograms).forEach(key => {
				fd.append(key, _vm.milagePrograms[key]);
			});
			this.axios
				.post("/milage-program/", fd)
				.then(function () {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		update() {
			let _vm = this;
			let fd = new FormData();
			if (_vm.milagePrograms.logoImage) {
				fd.append("logoImage", _vm.milagePrograms.logoImage);
			}
			Object.keys(_vm.milagePrograms).forEach(key => {
				if (_vm.milagePrograms[key] !== null) {
					fd.append(key, _vm.milagePrograms[key]);
				}
			});
			fd.append("_method", "PATCH");
			this.axios
				.post("/milage-program/" + this.milagePrograms.id, fd)
				.then(function (response) {
					_vm.milagePrograms = response.data.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},

		getDetail() {
			let _vm = this;
			this.axios
				.get("/milage-program/" + this.$route.params.id)
				.then(function (response) {
					_vm.milagePrograms = response.data.data;
					if (_vm.milagePrograms.full_image_url && _vm.milagePrograms.full_image_url.length > 0) {
						$("#imagePreview").css(
							"background-image",
							"url(" + _vm.milagePrograms.full_image_url + "?date=" + Date.now() + ")"
						);
						$("#imagePreview").hide();
						$("#imagePreview").fadeIn(650);
					}
				})
				.catch(function () { });
		},
		selectImage(event) {
			let _vm = this;
			var input = event.target;
			if (input.files && input.files[0]) {
				_vm.milagePrograms.logoImage = input.files[0];
				var reader = new FileReader();
				reader.onload = function (e) {
					$("#imagePreview").css(
						"background-image",
						"url(" + e.target.result + ")"
					);
					$("#imagePreview").hide();
					$("#imagePreview").fadeIn(650);
				};
				reader.readAsDataURL(input.files[0]);
			}
		},
		previewImage: function (event) {
			const input = event.target

			if (input.files && input.files[0]) {
				const reader = new FileReader()

				reader.onload = (e) => {
					this.milagePrograms.profile_image = e.target.result
				}

				reader.readAsDataURL(input.files[0])
			}
		}
	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
